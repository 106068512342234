import { PublicApiRequestBase } from './types';

/** Config endpoint used to setup client */
export type ConfigRequest = PublicApiRequestBase & {
  path: 'config';
  method: 'GET';
};

/** Config endpoint response */
export type PublicConfig = {
  MAINTENANCE_PAGE: string;
};

/* Initial value */
export const initialPublicConfig: PublicConfig = {
  MAINTENANCE_PAGE: '',
};
