'use client';
import React, {
  forwardRef,
  HTMLAttributes,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import { useMergeRefs } from '@floating-ui/react';
import styled, { css } from 'styled-components';
import { useExecuteOnce } from '@/common/hooks/useExecuteOnce';
import { useOnResize } from '@/common/hooks/useOnResize';
import { BaseTooltip } from '@/common/Tooltip/BaseTooltip';
import { useTooltip } from '@/common/Tooltip/useTooltip';
import { BaseColor } from '@/theme/colors';
import { ITexts } from '@/theme/texts';
import { StyledProps } from '@/types/helpers';

export type EditableTextVariant = keyof Omit<ITexts, 'FontFamily'>;

type BaseProps = {
  variant: EditableTextVariant;
  underlineColor?: BaseColor;
  disabledUnderlineColor?: BaseColor;
  placeholderColor?: BaseColor;
  color?: BaseColor;
  multiline?: boolean;
  isEditable?: boolean;
  stretch?: boolean;
  maxWidth?: number;
  className?: string;
};

const underlined = css`
  text-decoration-line: underline;
  text-decoration-style: dashed;
  text-decoration-thickness: 1px;
  text-underline-offset: 2px;
`;

const Input = styled.textarea<StyledProps<BaseProps>>`
  ${(p) => p.theme.texts[p.$variant]}
  ${(p) =>
    p.$multiline
      ? css`
          white-space: pre-line;
          padding: 0;
        `
      : css`
          ${p.theme.commonStyles.Ellipsis}
          grid-row: 1;
          grid-column: 1;
        `}

  width: 100%;
  background-color: transparent;
  border: 0;
  resize: none;
  overflow: hidden;
  outline: none;
  color: ${(p) => (p.$color ? p.theme.colors.base[p.$color] : 'inherit')};

  ${(p) =>
    p.$maxWidth &&
    css`
      max-width: ${p.$maxWidth}px;
    `}

  ${(p) =>
    !p.disabled &&
    p.$underlineColor &&
    css`
      ${underlined}
      text-decoration-color: ${p.theme.colors.base[p.$underlineColor]};
    `}
  ${(p) =>
    p.disabled &&
    p.$disabledUnderlineColor &&
    css`
      ${underlined}
      text-decoration-color: ${p.theme.colors.base[p.$disabledUnderlineColor]};
    `}
  ${(p) =>
    !p.disabled
      ? css`
          cursor: text;
          &:focus {
            text-decoration-color:: ${
              p.$underlineColor ? p.theme.colors.base.Blue3 : 'transparent'
            };
            text-overflow: unset;
            overflow: auto;
            // Hide the scrollbars
            &::-webkit-scrollbar {
              display: none;
            }
            -ms-overflow-style: none;
            scrollbar-width: none;
          }
          &::placeholder {
            color: ${p.theme.colors.base[p.$underlineColor ?? 'Gray3']};
          }
        `
      : css`
          cursor: inherit;
        `}

  ${(p) =>
    p.$placeholderColor &&
    css`
      &::placeholder {
        color: ${p.theme.colors.base[p.$placeholderColor]};
      }
    `}

  ${(p) =>
    !p.$isEditable &&
    css`
      user-select: none;
    `};
`;

type ContainerProps = StyledProps<
  Pick<BaseProps, 'multiline' | 'variant' | 'stretch' | 'maxWidth'>
>;

const Container = styled.div<ContainerProps>`
  ${(p) =>
    p.$multiline
      ? css`
          width: 100%;
        `
      : css`
          display: inline-grid;
          max-width: 100%;

          ${p.$stretch &&
          css`
            overflow: hidden;
          `}

          &::before {
            ${p.theme.texts[p.$variant]}
            content: attr(data-value);
            visibility: hidden;
            max-width: ${p.$maxWidth ? `${p.$maxWidth}px` : '100%'};
            ${!p.$stretch &&
            css`
              overflow: hidden;
            `}
            grid-row: 1;
            grid-column: 1;
          }
        `};
`;

export type EditableElements = HTMLTextAreaElement & HTMLInputElement;

export type ExposedEditableTextProps = {
  setInternalValue: (value: string) => void;
};

export type EditableTextProps = BaseProps & {
  children?: string;
  placeholder?: string;
  onChange?: (newValue: string) => void;
  isHovered?: boolean;
  getExposedProps?: (props: ExposedEditableTextProps) => void;
} & Omit<
    HTMLAttributes<EditableElements>,
    'contentEditable' | 'onChange' | 'onInput' | 'color' | 'children' | 'value'
  >;

export const EditableText = forwardRef<EditableElements, EditableTextProps>(
  (props, ref) => {
    const {
      variant,
      placeholder,
      underlineColor,
      disabledUnderlineColor,
      placeholderColor,
      color,
      isEditable = true,
      onChange,
      isHovered = false,
      children,
      spellCheck = false,
      suppressContentEditableWarning = true,
      onFocus,
      onBlur,
      multiline,
      getExposedProps,
      stretch = false,
      maxWidth,
      className,
      ...htmlAttributes
    } = props;
    const localRef = useRef<EditableElements>(null);

    const isFocused = useRef<boolean>(false);

    const [internalValue, setInternalValue] = useState<string>(children ?? '');

    const autoSize = useCallback(
      (element: EditableElements | null = localRef.current) => {
        if (element && multiline) {
          element.style.height = 'auto';
          element.style.height = `${element.scrollHeight}px`;
        }

        if (element && !multiline && stretch) {
          element.style.width = 'auto';
          element.style.width = `${element.scrollWidth}px`;
        }
      },
      [multiline, stretch]
    );

    useEffect(() => {
      if (!isFocused.current) {
        setInternalValue(children ?? '');
      }

      autoSize();
    }, [autoSize, children, internalValue]);

    const handleInput = useCallback(() => {
      let text = localRef.current?.value ?? '';
      if (/^\s+$/.test(text)) {
        text = '';
      }

      setInternalValue(text);
      autoSize();
      onChange?.(text);
    }, [autoSize, onChange]);

    const handleFocus = useCallback(
      (e: React.FocusEvent<EditableElements, Element>) => {
        isFocused.current = true;
        onFocus?.(e);
      },
      [onFocus]
    );

    const handleBlur = useCallback(
      (e: React.FocusEvent<EditableElements, Element>) => {
        if (!multiline) {
          localRef.current?.scrollTo({
            left: 0,
          });
        }

        isFocused.current = false;
        onBlur?.(e);
      },
      [multiline, onBlur]
    );

    const { ref: resizeRef } = useOnResize<EditableElements>(autoSize);
    const autoSizeOnLoad = useExecuteOnce(autoSize);

    const [showTooltip, setShowTooltip] = useState(false);

    const {
      floating: { refs, x, y, strategy },
      interactions: { getFloatingProps },
    } = useTooltip({ open: showTooltip && !multiline });

    const setRefs = useMergeRefs<EditableElements>([
      ref,
      localRef,
      resizeRef,
      autoSizeOnLoad,
      refs.setReference,
    ]);

    useEffect(() => {
      if (!isFocused.current && !multiline && localRef.current && isHovered) {
        const tolerance = 1;
        setShowTooltip(
          localRef.current.scrollWidth >
            localRef.current.clientWidth + tolerance
        );
      } else {
        setShowTooltip(false);
      }
    }, [multiline, isHovered]);

    const handleMouseOut = useCallback(() => {
      setShowTooltip(false);
    }, []);

    useEffect(() => {
      getExposedProps?.({
        setInternalValue,
      });
    }, [getExposedProps]);

    return (
      <Container
        $variant={variant}
        $multiline={multiline}
        $stretch={stretch}
        $maxWidth={maxWidth}
        data-value={internalValue || placeholder}
        className={className}
      >
        <Input
          ref={setRefs}
          $variant={variant}
          $underlineColor={underlineColor}
          $disabledUnderlineColor={disabledUnderlineColor}
          $color={color}
          $multiline={multiline}
          spellCheck={spellCheck}
          rows={multiline ? 1 : undefined}
          placeholder={placeholder}
          $placeholderColor={placeholderColor}
          suppressContentEditableWarning={suppressContentEditableWarning}
          onInput={handleInput}
          onFocus={handleFocus}
          onBlur={handleBlur}
          onMouseOut={handleMouseOut}
          value={internalValue}
          as={multiline ? 'textarea' : 'input'}
          {...htmlAttributes}
          disabled={!isEditable}
          $isEditable={isEditable}
          size={1}
          $stretch={stretch}
          $maxWidth={maxWidth}
        />
        {!multiline && (
          <BaseTooltip // Looks like the EllipsisTooltip
            type="Dark"
            maxWidth="400px"
            content={internalValue}
            floating={{
              getFloatingProps,
              refs,
              x,
              y,
              strategy,
            }}
            open={showTooltip}
          />
        )}
      </Container>
    );
  }
);
