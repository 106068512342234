'use client';
import React, { FC, PropsWithChildren, Suspense } from 'react';
import { I18nextProvider } from 'react-i18next';
import { Maintenance } from '@/common/Maintenance';
import { AutoSavingProvider } from '@/common/providers/AutoSavingProvider/AutoSavingProvider';
import { MessagingProvider } from '@/common/providers/MessagingProvider/MessagingProvider';
import { Spinner } from '@/common/Spinner';
import { i18n } from '@/i18n/i18n';
import { Theme } from '@/theme/theme';

// Show the build number in the logs
if (process.env.NEXT_PUBLIC_BUILD_NUMBER) {
  console.log(`build ${process.env.NEXT_PUBLIC_BUILD_NUMBER}`);
}

const AppLayout: FC<PropsWithChildren> = ({ children }) => (
  <html>
    <head>
      <link
        rel="stylesheet"
        href="https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@300;400;600;700&display=swap"
      />
      <link
        rel="stylesheet"
        href="https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@300;400;600;700&display=swap&text=%E2%86%90|%E2%86%92"
      />
      <link rel="icon" href="/static/favicon.ico" />
      <meta name="viewport" content="width=device-width, initial-scale=1" />
      <meta name="theme-color" content="#000000" />
      <meta name="og:type" content="website" />
      <meta name="og:title" content="rellify" />
      <meta name="description" content="app.rellify.com" />
      <meta name="og:description" content="app.rellify.com" />
      <script
        data-jsd-embedded
        data-key="01241b45-9e1c-493c-8056-3dad06348c43"
        data-base-url="https://jsd-widget.atlassian.com"
        src="https://jsd-widget.atlassian.com/assets/embed.js"
        defer
      />
      <title>rellify</title>
    </head>
    <body>
      <div id="root">
        <Theme>
          <Suspense fallback={<Spinner />}>
            <I18nextProvider i18n={i18n}>
              <MessagingProvider>
                <AutoSavingProvider>
                  <Maintenance>{children}</Maintenance>
                </AutoSavingProvider>
              </MessagingProvider>
            </I18nextProvider>
          </Suspense>
        </Theme>
      </div>
      <div id="hidden"></div>
    </body>
  </html>
);

export default AppLayout;
