import { css } from 'styled-components';
import { IBaseColors } from './colors';
import { TypedCSS, CSS } from './types';
import { StyledProps } from '@/types/helpers';

export type ScrollbarProps = {
  scrollbarColor?: keyof IBaseColors;
  scrollbarBackgroundColor?: keyof IBaseColors;
};

type StyledScrollbarProps = StyledProps<ScrollbarProps>;

export interface ICommonStyles {
  Scrollbar: TypedCSS<StyledScrollbarProps>;
  ContentPadding: CSS;
  ShadowSmall: CSS;
  ShadowLarge: CSS;
  Ellipsis: CSS;
  OpacityWithDisabled: CSS;
}

const Scrollbar = css<StyledScrollbarProps>`
  ${(p) => {
    const color = p.theme.colors.base[p.$scrollbarColor ?? 'Gray4'];
    const scrollbarBackgroundColor =
      p.theme.colors.base[p.$scrollbarBackgroundColor ?? 'White1'];
    const scrollbarBorder = `2px ${scrollbarBackgroundColor} solid`;

    return css`
      // firefox
      scrollbar-color: ${color} transparent;
      scrollbar-width: thin;

      // webkit
      ::-webkit-scrollbar {
        width: 10px;
        height: 10px;
      }
      ::-webkit-scrollbar-track {
        background: transparent;
        border-radius: 4px;
        margin-left: 2px;
      }
      ::-webkit-scrollbar-track:vertical {
        margin-top: 2px;
        margin-bottom: 2px;
      }
      ::-webkit-scrollbar-track:horizontal {
        margin-left: 2px;
        margin-right: 2px;
      }
      ::-webkit-scrollbar-thumb {
        background: ${color};
        border-radius: 4px;
        background-clip: padding-box;
      }
      ::-webkit-scrollbar-thumb:vertical {
        border-right: ${scrollbarBorder};
        border-left: ${scrollbarBorder};
      }
      ::-webkit-scrollbar-thumb:horizontal {
        border-top: ${scrollbarBorder};
        border-bottom: ${scrollbarBorder};
      }
    `;
  }}
`;

const ContentPadding = css`
  padding: 16px 32px 16px 32px;
`;

const shadowBase = css`
  background: ${(p) => p.theme.colors.base.White1};
  border-radius: 6px;
`;

const ShadowSmall = css`
  ${shadowBase}
  ${(p) => p.theme.shadows.Selected};
`;

const ShadowLarge = css`
  ${shadowBase}
  ${(p) => p.theme.shadows.Dropdown};
`;

const Ellipsis = css`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const OpacityWithDisabled = css`
  opacity: 0.5;
  pointer-events: none;
`;

export const CommonStyles: ICommonStyles = {
  Scrollbar,
  ContentPadding,
  ShadowSmall,
  ShadowLarge,
  Ellipsis,
  OpacityWithDisabled,
};
