import { css } from 'styled-components';
import type { CSS } from './types';

export interface ITexts {
  Common: CSS;
  Data1: CSS;
  Data2: CSS;
  Headline1: CSS;
  Headline2: CSS;
  Headline3: CSS;
  Headline4: CSS;
  Headline5: CSS;
  Headline6: CSS;
  Title1: CSS;
  Title2: CSS;
  Paragraph1: CSS;
  Paragraph2: CSS;
  Caption1: CSS;
  Caption2: CSS;
}

const common = css`
  /* reset css */
  margin: 0;
  font: inherit;
  //TODO: Check the default values from browser (like padding)

  /* set font family */
  font-family: ${(p) => p.theme.typography.fontFamily};
`;

const Data1 = css`
  ${common}
  font-size: 47px;
  font-weight: 300;
  letter-spacing: 0;
  line-height: 59px;
`;

const Data2 = css`
  ${common}
  font-size: 33px;
  font-weight: 300;
  letter-spacing: 0;
  line-height: 41px;
`;

const Headline1 = css`
  ${common}
  font-size: 47px;
  letter-spacing: 0;
  line-height: 59px;
`;

const Headline2 = css`
  ${common}
  font-size: 33px;
  letter-spacing: 0;
  line-height: 41px;
`;

const Headline3 = css`
  ${common}
  font-size: 25px;
  letter-spacing: 0;
  line-height: 32px;
`;

const Headline4 = css`
  ${common}
  font-size: 21px;
  letter-spacing: 0;
  line-height: 27px;
`;

// H5
const Title1 = css`
  ${common}
  font-size: 18px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 23px;
`;

// H6
const Title2 = css`
  ${common}
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 20px;
`;

const Paragraph1 = css`
  ${common}
  font-size: 18px;
  letter-spacing: 0;
  line-height: 23px;
`;

const Paragraph2 = css`
  ${common}
  font-size: 16px;
  letter-spacing: 0;
  line-height: 20px;
`;

const Caption1 = css`
  ${common}
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 18px;
`;

const Caption2 = css`
  ${common}
  font-size: 14px;
  letter-spacing: 0;
  line-height: 18px;
`;

export const Texts: ITexts = {
  Common: common,
  Data1,
  Data2,
  Headline1,
  Headline2,
  Headline3,
  Headline4,
  Headline5: Title1,
  Headline6: Title2,
  Title1,
  Title2,
  Paragraph1,
  Paragraph2,
  Caption1,
  Caption2,
};
