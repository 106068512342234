import { useCallback, useRef } from 'react';

export const useExecuteOnce = <T extends (...args: any) => any>(
  operation: T
) => {
  const once = useRef<boolean>(false);
  return useCallback(
    (...params: Parameters<T>) => {
      if (!once.current) {
        once.current = true;
        operation.call(this, ...params);
      }
    },
    [operation]
  );
};
