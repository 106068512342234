import { ConfigRequest } from './config';
import { GetOrganizationIdRequest } from './getOrganizationId';
import { WebsiteToPdfRequest } from './websiteToPdf';

export type PublicApiRequest =
  | GetOrganizationIdRequest
  | ConfigRequest
  | WebsiteToPdfRequest;

/** Makes a request to the public rellify api */
export const publicApi = async ({
  path,
  method,
  body,
  query,
  contentType = 'application/json',
}: PublicApiRequest) => {
  const headers = new Headers();
  headers.append('Content-Type', contentType);

  // Construct query string
  const queryString = query ? `?${new URLSearchParams(query).toString()}` : '';
  // Make request
  const request = new Request(
    `${process.env.NEXT_PUBLIC_PUBLIC_API_ENDPOINT}${path}${queryString}`
  );
  const response = await fetch(request, {
    method,
    headers,
    mode: 'cors',
    body: body ? JSON.stringify(body) : undefined,
    cache: 'no-cache',
  });

  return response;
};

/** Makes a request to the public rellify api and returns the body as json */
export const publicApiJSON = async <T extends {}>(request: PublicApiRequest) =>
  (await publicApi(request)).json() as Promise<T>;
