import { useRef, useState, Ref } from 'react';

/**
 * Sets a ref to an element and triggers a new layout cycle,
 * when the ref is set or updated.
 *
 * @template T
 * @return {*}
 */
export const useRefElement = <T extends Element>(
  onRefSet?: (element: T | null) => void
) => {
  const [element, setElement] = useState<T | null>(null);

  const customRef = useRef<Ref<T>>({
    set current(value) {
      setElement(value);
      onRefSet?.(value);
    },
    get current() {
      return element;
    },
  });

  return {
    element,
    ref: customRef.current,
  };
};
