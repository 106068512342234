export interface ISizes {
  margin05x: string;
  margin1x: string;
  margin2x: string;
  margin3x: string;
  margin4x: string;
}

export const Sizes: ISizes = {
  margin05x: '4px',
  margin1x: '8px',
  margin2x: '16px',
  margin3x: '24px',
  margin4x: '32px',
};
