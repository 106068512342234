import { css } from 'styled-components';
import type { CSS } from './types';

class Animation {
  private animationDuration: number;

  public keyframes: CSS;

  public animationString: string;

  public get animationCss(): CSS {
    return css`
      animation: ${this.animationString};
    `;
  }

  public animate(element: HTMLElement) {
    element.style.animation = this.animationString;
    if (this.animationDuration) {
      window.setTimeout(() => {
        element.style.animation = '';
      }, this.animationDuration + 1);
    }
  }

  constructor(
    keyframes: CSS,
    animationString: string,
    animationDuration: number = 0
  ) {
    this.keyframes = keyframes;
    this.animationString = animationString;
    this.animationDuration = animationDuration;
  }
}

export interface IAnimations {
  Shake: Animation;
  FadeIn: Animation;
  MoveCard: Animation;
}

const Shake = new Animation(
  css`
    @keyframes shake {
      0% {
        transform: translateX(0);
      }
      25% {
        transform: translateX(-3px);
      }
      50% {
        transform: translateX(0);
      }
      75% {
        transform: translateX(3px);
      }
    }
  `,
  'shake 0.15s linear 0s 3',
  150 * 3
);

const FadeIn = new Animation(
  css`
    @keyframes fadeIn {
      from {
        opacity: 0;
      }
      to {
        opacity: 1;
      }
    }
  `,
  'fadeIn 0.4s',
  400
);

const MoveCard = new Animation(
  css`
    @keyframes moveCard {
      from {
        margin-top: 0;
        margin-bottom: 0;
        ${(p) => p.theme.shadows.Selected}
      }
      to {
        margin-top: -4px;
        margin-bottom: 4px;
        ${(p) => p.theme.shadows.Dropdown}
      }
    }
  `,
  'moveCard 0.2s',
  200
);

export const Animations: IAnimations = {
  Shake,
  FadeIn,
  MoveCard,
};
