'use client';
import React, { FC, useContext, useEffect, useState, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useInterval } from 'react-use';
import { getBuildNumber } from '@/app/actions';
import { DisableAll } from '@/common/DisableAll';
import { AutoSavingContext } from '@/common/providers/AutoSavingProvider/AutoSavingContext';
import { MessagingContext } from '@/common/providers/MessagingProvider/MessagingContext';
import { AutoSavingStatus } from '@/types/autoSaving';

const updateInstalledKey = 'updateInstalled';

type AppUpdaterProps = {
  inMaintenance?: boolean;
};

export const AppUpdater: FC<AppUpdaterProps> = ({ inMaintenance }) => {
  const { status } = useContext(AutoSavingContext);
  const { setSuccessMessage } = useContext(MessagingContext);
  const { t } = useTranslation();
  const [isUpdating, setIsUpdating] = useState(false);

  const checkForUpdate = useCallback(async () => {
    const buildNumber = await getBuildNumber();

    // Detect whether the build number on the server-side changed
    if (
      process.env.NEXT_PUBLIC_NODE_ENV !== 'development' &&
      buildNumber !== process.env.NEXT_PUBLIC_BUILD_NUMBER
    ) {
      setIsUpdating(true);
    }
  }, []);

  // Check for updates, if not in dev mode
  useInterval(
    checkForUpdate,
    process.env.NEXT_PUBLIC_NODE_ENV === 'development' ? null : 4000
  );

  // Handle the update by refreshing the browser
  useEffect(() => {
    // Run only if the update process is currently not blocked by a running mutation in the auto saving
    if (
      isUpdating &&
      status !== AutoSavingStatus.InProgress &&
      status !== AutoSavingStatus.Retrying
    ) {
      window.localStorage.setItem(
        updateInstalledKey,
        process.env.NEXT_PUBLIC_BUILD_NUMBER
      );
      window.location.reload();
    }
  }, [isUpdating, status]);

  // Show a success message after the page was reloaded
  useEffect(() => {
    if (!inMaintenance && window.localStorage.getItem(updateInstalledKey)) {
      window.localStorage.removeItem(updateInstalledKey);
      setSuccessMessage(t('global:updateInstalled'));
    }
  }, [inMaintenance, setSuccessMessage, t]);

  /*
    Disable user interactions and show a spinner on top of the current page to prevent
    new mutations from being triggered and to finish the current ones.
  */
  return <DisableAll variant={isUpdating ? 'spinner' : 'none'} />;
};
