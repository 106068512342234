import { css } from 'styled-components';
import type { CSS } from './types';

export interface IDiscoverFilters {
  All: CSS;
  TopicGems: CSS;
  ExpandExpertise: CSS;
  BuildExpertise: CSS;
  BestForSeries: CSS;
  FastRanking: CSS;
  ZeroCompetition: CSS;
}
export interface IShadows {
  Selected: CSS;
  Dropdown: CSS;
  StickyHeader: CSS;
  StickyHeaderMasked: CSS;
  Dashboard: CSS;
  DiscoverFilters: IDiscoverFilters;
  Drag: CSS;
  Card: CSS;
}

const DiscoverFilters: IDiscoverFilters = {
  All: css`
    box-shadow: 0px 7px 22px 0px rgba(122, 139, 148, 0.15);
  `,
  TopicGems: css`
    box-shadow: 0px 7px 22px 0px rgba(243, 146, 0, 0.15);
  `,
  ExpandExpertise: css`
    box-shadow: 0px 7px 22px 0px rgba(124, 82, 222, 0.15);
  `,
  BuildExpertise: css`
    box-shadow: 0px 7px 22px 0px rgba(190, 58, 124, 0.15);
  `,
  BestForSeries: css`
    box-shadow: 0px 7px 22px 0px rgba(255, 98, 103, 0.15);
  `,
  FastRanking: css`
    box-shadow: 0px 7px 22px 0px rgba(3, 151, 62, 0.15);
  `,
  ZeroCompetition: css`
    box-shadow: 0px 7px 22px 0px rgba(1, 121, 119, 0.15);
  `,
};

export const Shadows: IShadows = {
  Selected: css`
    box-shadow: 0px 1px 2px rgba(69, 79, 104, 0.3);
  `,
  Dropdown: css`
    box-shadow: 0px 7px 22px rgba(22, 30, 37, 0.2);
  `,
  StickyHeader: css`
    box-shadow: 0px 1px 7px rgba(111, 127, 139, 0.2);
  `,
  StickyHeaderMasked: css`
    box-shadow: inset 0px 2px 7px rgba(111, 127, 139, 0.19);
  `,
  Dashboard: css`
    box-shadow: 0px 5px 21px 0px rgba(25, 34, 41, 0.1);
  `,
  DiscoverFilters: DiscoverFilters,
  Drag: css`
    box-shadow: 0px 7px 22px 0px rgba(22, 30, 37, 0.2);
  `,
  Card: css`
    box-shadow: 0px 1px 7px 0px rgba(111, 127, 139, 0.2);
  `,
};
