'use client';
import React, { forwardRef, PropsWithChildren } from 'react';
import styled, { css } from 'styled-components';
import { BaseColor } from '@/theme/colors';
import { ITexts } from '@/theme/texts';
import { StyledProps } from '@/types/helpers';

export type Variant = keyof Omit<ITexts, 'FontFamily'>;

type Align = 'Left' | 'Right' | 'Center';

export type TextProps = PropsWithChildren & {
  variant: Variant;
  color?: BaseColor;
  align?: Align;
  className?: string;
  muted?: boolean;
  id?: string;
  fontStyle?: 'normal' | 'italic';
  ellipsis?: boolean;
  underline?: boolean;
  htmlElement?: keyof JSX.IntrinsicElements;
  noWrap?: boolean;
  overflowBreak?: 'normal' | 'anywhere' | 'break-word';
} & Partial<Pick<HTMLElement, 'onclick'>>;

const commonTextCss = css<
  StyledProps<
    Pick<
      TextProps,
      | 'color'
      | 'muted'
      | 'align'
      | 'fontStyle'
      | 'ellipsis'
      | 'noWrap'
      | 'overflowBreak'
      | 'underline'
    >
  >
>`
  ${(p) =>
    !!p.$align &&
    css`
      text-align: ${p.$align.toLowerCase()};
    `}
  ${(p) => {
    if (p.$muted) {
      return css`
        color: ${p.theme.colors.base.Gray2};
      `;
    }

    if (p.$color) {
      return css`
        color: ${p.theme.colors.base[p.$color]};
      `;
    }

    return '';
  }}
  ${(p) =>
    !!p.$fontStyle &&
    css`
      font-style: ${p.$fontStyle};
    `}
    ${(p) =>
    p.$ellipsis &&
    css`
      ${p.theme.commonStyles.Ellipsis}
    `}

    ${(p) =>
    !!p.$overflowBreak &&
    css`
      word-break: ${p.$overflowBreak};
    `}
      
    ${(p) =>
    !!p.$noWrap &&
    css`
      white-space: nowrap;
    `}

    ${(p) =>
    !!p.$underline &&
    css`
      text-decoration: underline;
    `};
`;

const StyledData1 = styled.span`
  ${(p) => p.theme.texts.Data1}
  ${commonTextCss}
`;

const StyledData2 = styled.span`
  ${(p) => p.theme.texts.Data2}
  ${commonTextCss}
`;

const StyledHeadline1 = styled.h1`
  ${(p) => p.theme.texts.Headline1}
  ${commonTextCss}
`;

const StyledHeadline2 = styled.h2`
  ${(p) => p.theme.texts.Headline2}
  ${commonTextCss}
`;

const StyledHeadline3 = styled.h3`
  ${(p) => p.theme.texts.Headline3}
  ${commonTextCss}
`;

const StyledHeadline4 = styled.h4`
  ${(p) => p.theme.texts.Headline4}
  ${commonTextCss}
`;

const StyledHeadline5 = styled.h5`
  ${(p) => p.theme.texts.Headline5}
  ${commonTextCss}
`;

const StyledHeadline6 = styled.h6`
  ${(p) => p.theme.texts.Headline6}
  ${commonTextCss}
`;

const StyledTitle1 = styled.span`
  ${(p) => p.theme.texts.Title1}
  ${commonTextCss}
`;

const StyledTitle2 = styled.span`
  ${(p) => p.theme.texts.Title2}
  ${commonTextCss}
`;

const StyledParagraph1 = styled.p`
  ${(p) => p.theme.texts.Paragraph1}
  ${commonTextCss}
`;

const StyledParagraph2 = styled.p`
  ${(p) => p.theme.texts.Paragraph2}
  ${commonTextCss}
`;

const StyledCaption1 = styled.span`
  ${(p) => p.theme.texts.Caption1}
  ${commonTextCss}
`;

const StyledCaption2 = styled.span`
  ${(p) => p.theme.texts.Caption2}
  ${commonTextCss}
`;

const StyledDefaultSpan = styled.span`
  ${commonTextCss}
`;

export const Text = forwardRef<HTMLElement, TextProps>((props, ref) => {
  const {
    variant,
    children,
    color,
    align,
    muted,
    fontStyle,
    ellipsis,
    noWrap,
    overflowBreak,
    underline,
    htmlElement,
    ...rest
  } = props;

  const params = {
    $color: color,
    $muted: muted,
    $align: align,
    $fontStyle: fontStyle,
    $ellipsis: ellipsis,
    $noWrap: noWrap,
    $overflowBreak: overflowBreak,
    $underline: underline,
    ref: ref as any,
    as: htmlElement as any,
    ...rest,
    children,
  };

  switch (variant) {
    case 'Data1':
      return <StyledData1 {...params} />;
    case 'Data2':
      return <StyledData2 {...params} />;
    case 'Headline1':
      return <StyledHeadline1 {...params} />;
    case 'Headline2':
      return <StyledHeadline2 {...params} />;
    case 'Headline3':
      return <StyledHeadline3 {...params} />;
    case 'Headline4':
      return <StyledHeadline4 {...params} />;
    case 'Headline5':
      return <StyledHeadline5 {...params} />;
    case 'Headline6':
      return <StyledHeadline6 {...params} />;
    case 'Title1':
      return <StyledTitle1 {...params} />;
    case 'Title2':
      return <StyledTitle2 {...params} />;
    case 'Paragraph1':
      return <StyledParagraph1 {...params} />;
    case 'Paragraph2':
      return <StyledParagraph2 {...params} />;
    case 'Caption1':
      return <StyledCaption1 {...params} />;
    case 'Caption2':
      return <StyledCaption2 {...params} />;
    default:
      return <StyledDefaultSpan {...params} />;
  }
});
