'use client';
import React, {
  FC,
  ReactElement,
  useState,
  useContext,
  useCallback,
  useEffect,
  PropsWithChildren,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useBeforeUnload, useDeepCompareEffect } from 'react-use';
import {
  AutoSavingContext,
  AutoSavingContextType,
  Execution,
  Executions,
} from './AutoSavingContext';
import { AutoSavingError, AutoSavingStatus } from '@/types/autoSaving';

let currentAutoSavingContext: AutoSavingContextType | undefined;

export const getCurrentAutoSavingContext = () => currentAutoSavingContext;

const CurrentSetter = () => {
  const context = useContext(AutoSavingContext);
  useEffect(() => {
    currentAutoSavingContext = context;
    return () => {
      if (currentAutoSavingContext === context) {
        currentAutoSavingContext = undefined;
      }
    };
  }, [context]);
  return <></>;
};

export const AutoSavingProvider: FC<PropsWithChildren> = (
  props
): ReactElement => {
  const { children } = props;
  const { t } = useTranslation();
  const [executions, setExecutions] = useState<Executions>({});
  const [status, setStatus] = useState<AutoSavingStatus | undefined>();
  const [error, setError] = useState<AutoSavingError | undefined>();

  useDeepCompareEffect(() => {
    const values = Object.values(executions);

    const withError = values.find((v) => v.error !== undefined);
    if (withError) {
      setError(withError.error);
    } else {
      setError(undefined);
    }

    if (values.some((v) => v.status === AutoSavingStatus.Failed)) {
      setStatus(AutoSavingStatus.Failed);
      return;
    }

    if (values.some((v) => v.status === AutoSavingStatus.Retrying)) {
      setStatus(AutoSavingStatus.Retrying);
      return;
    }

    if (values.some((v) => v.status === AutoSavingStatus.InProgress)) {
      setStatus(AutoSavingStatus.InProgress);
      return;
    }

    if (
      values.length > 0 &&
      !values.some((v) => v.status !== AutoSavingStatus.Success)
    ) {
      setStatus(AutoSavingStatus.Success);
    }
  }, [executions]);

  // Show a prompt, if the user tries to leave the page during saving
  const canLeave = status === undefined || status === AutoSavingStatus.Success;
  useBeforeUnload(() => !canLeave, t('autoSaving:dialog:title'));

  const updateExecution = useCallback((u: Execution) => {
    setExecutions((exec) => ({ ...exec, [u.id]: { ...exec[u.id], ...u } }));
  }, []);

  const removeExecution = useCallback((id: string) => {
    setExecutions((exec) => {
      const result = { ...exec };
      delete result[id];
      return result;
    });
  }, []);

  const retry = useCallback(() => {
    Object.values(executions)
      .filter((execution) => execution.status === AutoSavingStatus.Failed)
      .forEach((execution) => execution.retry?.());
  }, [executions]);

  return (
    <AutoSavingContext.Provider
      value={{
        error,
        status,
        retry,
        updateExecution,
        removeExecution,
      }}
    >
      {children}
      <CurrentSetter />
    </AutoSavingContext.Provider>
  );
};
