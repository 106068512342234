import { createContext } from 'react';
import {
  AutoSaving,
  AutoSavingError,
  AutoSavingStatus,
} from '@/types/autoSaving';

export type Execution = {
  id: string;
  status?: AutoSavingStatus;
  retry?: () => void;
  error?: AutoSavingError;
};

export type Executions = { [id: string]: Execution };

export type AutoSavingContextType = {
  retry: () => void;
  updateExecution: (u: Execution) => void;
  removeExecution: (id: string) => void;
} & AutoSaving;

export const AutoSavingContext = createContext<AutoSavingContextType>({
  retry: () => {},
  updateExecution: () => {},
  removeExecution: () => {},
});
