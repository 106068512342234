'use client';
import React, { FC } from 'react';
import Lottie from 'lottie-react';
import ReactDOM from 'react-dom';
import styled from 'styled-components';
import loadingAnimation from '@/animations/loading-animation.json';
import { Spinner } from '@/common/Spinner';

const DisablePage = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: #fff;
  opacity: 0;
  z-index: 999999999999;
`;

const LoadingAiWrapper = styled.div`
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 999999999998;
  width: 112px;
  height: 112px;
  border: 1px solid transparent;
  border-radius: 6px;
  background-image: ${() => {
    return `linear-gradient(#ffffff, #ffffff),
    linear-gradient(to right, #7c52de, #be3a7c)`;
  }};
  background-origin: border-box;
  background-clip: padding-box, border-box;
  ${(p) => p.theme.shadows.Dropdown};
`;

export type DisableAllVariants = 'none' | 'spinner' | 'gpt';

export type DisableAllProps = {
  variant?: DisableAllVariants;
};

export const DisableAll: FC<DisableAllProps> = ({ variant = 'none' }) => (
  <>
    {variant !== 'none' &&
      ReactDOM.createPortal(
        <>
          {variant === 'spinner' && <Spinner variant="Page" />}
          {variant === 'gpt' && (
            <LoadingAiWrapper>
              <Lottie animationData={loadingAnimation} loop={true} />
            </LoadingAiWrapper>
          )}
          <DisablePage />
        </>,
        document.body
      )}
  </>
);
