import { useEffect, useState, useCallback, useRef } from 'react';
import isEqual from 'lodash/isEqual';
import { useInterval } from 'react-use';
import {
  PublicConfig,
  initialPublicConfig,
} from '@/common/utils/publicApi/config';
import { publicApiJSON } from '@/common/utils/publicApi/publicApi';

/**
 * Returns the public config, including endpoints the frontend connects to.
 * The api endpoint is retrieved dynamically since the proxy does not support subscriptions
 * Also provides the frontend with the cdn distribution
 */
const getPublicConfig: () => Promise<PublicConfig> = async () => {
  const res = await publicApiJSON<PublicConfig>({
    path: 'config',
    method: 'GET',
  });
  return {
    ...res,
  };
};

export const usePublicConfig = () => {
  const [publicConfig, setPublicConfig] =
    useState<PublicConfig>(initialPublicConfig);
  const publicConfigWithoutCycle = useRef<PublicConfig>(publicConfig);

  const refetch = useCallback(async () => {
    const response = await getPublicConfig();
    if (!isEqual(publicConfigWithoutCycle.current, response)) {
      publicConfigWithoutCycle.current = response;
      setPublicConfig(response);
    }
  }, []);

  // Poll frequently for an update
  useInterval(refetch, 20000);

  useEffect(() => {
    // Fetch initially
    refetch();
  }, [refetch]);

  return {
    publicConfig,
    refetch,
  };
};
