'use client';
import React, { FC, useCallback, PropsWithChildren } from 'react';
import {
  ToastContainer,
  toast,
  ToastContent,
  ToastOptions,
} from 'react-toastify';
import styled, { useTheme, createGlobalStyle } from 'styled-components';
import { v4 as uuid } from 'uuid';
import { MessagingContext, SetMessage } from './MessagingContext';
import { Button } from '@/common/buttons/Button';
import { CloseIcon, CompletedIcon, NotCompletedIcon } from '@/common/icons';
import { Text } from '@/common/Text';
import 'react-toastify/dist/ReactToastify.min.css';

const Toast = styled.div`
  display: flex;
  flex-direction: column;
  grid-gap: 8px;
  margin-top: 2px;
  align-items: start;
`;

const RootStyle = createGlobalStyle`
  :root {
    // Overwrite toastify variables
    --toastify-toast-min-height: 56px;
    --toastify-toast-width: 400px;
    --toastify-text-color-light: ${(p) => p.theme.colors.base.Gray1};
  }
`;

const Container = styled(ToastContainer)`
  .Toastify__toast {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 16px;
    background: ${(p) => p.theme.colors.base.White1};
    ${(p) => p.theme.shadows.Dashboard}
    margin: 0;
  }
  .Toastify__toast-body {
    flex: 1;
    display: grid;
    grid-template-columns: auto 1fr;
    grid-gap: 8px;
    align-items: start;
    padding: 0;
    margin: 0;
  }
  .Toastify__toast-icon {
    width: auto;
    margin-inline-end: unset;
  }

  z-index: 999999999;
`;

export const MessagingProvider: FC<PropsWithChildren> = ({ children }) => {
  const { colors } = useTheme();

  const setMessage = useCallback(
    (
      showToast: (
        content: ToastContent<unknown>,
        options?: ToastOptions<unknown>
      ) => void,
      message: string,
      content?: (dismiss: () => void) => JSX.Element,
      options?: ToastOptions<unknown>
    ) => {
      const toastId = uuid();
      showToast(
        <Toast>
          <Text variant="Paragraph2" color="Gray1">
            {message}
          </Text>
          {content ? content(() => toast.dismiss(toastId)) : null}
        </Toast>,
        {
          toastId,
          closeButton: ({ closeToast }) => (
            <Button
              variant="Tertiary"
              color="Black"
              size="XS"
              icon={<CloseIcon color={colors.base.Gray3} />}
              onClick={closeToast}
            />
          ),
          ...options,
        }
      );
    },
    [colors.base.Gray3]
  );

  const setSuccessMessage = useCallback<SetMessage>(
    (message, content, options) =>
      setMessage(toast.success, message, content, {
        icon: <CompletedIcon color={colors.base.Green3} />,
        ...options,
      }),
    [colors.base.Green3, setMessage]
  );

  const setErrorMessage = useCallback<SetMessage>(
    (message, content, options) =>
      setMessage(toast.error, message, content, {
        icon: <NotCompletedIcon color={colors.base.Red3} />,
        ...options,
      }),
    [colors.base.Red3, setMessage]
  );

  const setWarningMessage = useCallback<SetMessage>(
    (message, content, options) =>
      setMessage(toast.warning, message, content, {
        icon: <NotCompletedIcon color={colors.base.BrandYellow} />,
        ...options,
      }),
    [colors.base.BrandYellow, setMessage]
  );

  return (
    <MessagingContext.Provider
      value={{ setSuccessMessage, setErrorMessage, setWarningMessage }}
    >
      {children}
      <Container
        position="bottom-center"
        autoClose={6000}
        hideProgressBar
        newestOnTop={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        stacked
        closeButton
      />
      <RootStyle />
    </MessagingContext.Provider>
  );
};
