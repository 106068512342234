export const parseMarkdown = (str: string): RecommendationTranslation => {
  const [title, description, recommendation] = str.split(`|||
`);
  return { title, description, recommendation };
};

export const camelToKebabCase = (str: string) =>
  str.replace(/[A-Z]/g, (letter) => `-${letter.toLowerCase()}`);

type Markdown = { [key: string]: any };

export type RecommendationTranslation = {
  title: string;
  description: string;
  recommendation: string;
};

export type RecommendationTranslations = {
  [key: string]: RecommendationTranslation;
};

export const getOneClickAnalysisRecommendations = (markdown: Markdown) => ({
  oneClickAnalysis: {
    recommendations: Object.entries(
      markdown
    ).reduce<RecommendationTranslations>((acc, [key, value]) => {
      acc[camelToKebabCase(key)] = parseMarkdown(value.default);
      return acc;
    }, {} as RecommendationTranslations),
  },
});

/** Extracts only the BCP 47 language tag from locale
 * i.e. en_US => en
 **/
export const getLanguageFromLocale = (language: string) =>
  language.split('_')[0];
