'use client';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { BaseErrorPage } from '@/common/errors/BaseErrorPage';
import { MaintenanceIcon } from '@/common/icons';

export const MaintenancePage: FC = () => {
  const { t } = useTranslation();
  return (
    <BaseErrorPage
      title={t('maintenance:maintenancePage:title')}
      description={t('maintenance:maintenancePage:description')}
      icon={<MaintenanceIcon width={48} height={48} />}
    />
  );
};
