export enum AutoSavingStatus {
  InProgress,
  Retrying,
  Failed,
  Success,
}

export enum AutoSavingError {
  Unexpected,
  NetworkError,
}

export type AutoSaving = {
  status?: AutoSavingStatus;
  error?: AutoSavingError;
};
