import { useCallback, useEffect, useRef } from 'react';
import { useRefElement } from './useRefElement';

export const useOnResize = <T extends HTMLElement | SVGElement>(
  onResize: (element: T) => void
) => {
  const observer = useRef<ResizeObserver>();

  const onRefSet = useCallback(
    (element: T | null) => {
      const obs = new ResizeObserver(() => {
        if (element) {
          onResize(element);
        }
      });
      if (element) {
        obs.observe(element);
      }

      // Disconnect the old observer
      observer.current?.disconnect();

      // Set the new observer
      observer.current = obs;
    },
    [onResize]
  );

  useEffect(() => {
    // Disconnect the observer when the component unmounts
    return () => {
      observer.current?.disconnect();
    };
  }, [observer]);

  return useRefElement<T>(onRefSet);
};
