import { createContext } from 'react';
import { ToastOptions } from 'react-toastify';

export type SetMessage = (
  message: string,
  content?: (dismiss: () => void) => JSX.Element,
  options?: Omit<ToastOptions<unknown>, 'closeButton' | 'toastId'>
) => void;

type ContextType = {
  setSuccessMessage: SetMessage;
  setErrorMessage: SetMessage;
  setWarningMessage: SetMessage;
};

export const MessagingContext = createContext<ContextType>({
  setSuccessMessage: () => {},
  setErrorMessage: () => {},
  setWarningMessage: () => {},
});
