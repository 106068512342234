export interface IHeights {
  topBar: string;
  navigationTab: string;
  contentMargin: string;
}

export const Heights: IHeights = {
  topBar: '64px',
  navigationTab: '49px',
  contentMargin: '8px',
};
