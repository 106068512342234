'use client';
import React, { FC, PropsWithChildren } from 'react';
import { AppUpdater } from './AppUpdater';
import { FeatureUpdatesPage } from './FeatureUpdatesPage';
import { MaintenancePage } from './MaintenancePage';
import { usePublicConfig } from './usePublicConfig';

export const Maintenance: FC<PropsWithChildren> = ({ children }) => {
  const {
    publicConfig: { MAINTENANCE_PAGE },
  } = usePublicConfig();

  if (MAINTENANCE_PAGE === 'maintenance') {
    return (
      <>
        <MaintenancePage />
        <AppUpdater inMaintenance />
      </>
    );
  }

  if (MAINTENANCE_PAGE === 'features') {
    return (
      <>
        <FeatureUpdatesPage />
        <AppUpdater inMaintenance />
      </>
    );
  }

  return (
    <>
      {children}
      <AppUpdater />
    </>
  );
};
