export interface IBaseColors {
  Gray1: string;
  Gray2: string;
  Gray3: string;
  Gray3060: string;
  Gray4: string;
  Gray5: string;
  White1: string;
  White2: string;
  Blue1: string;
  Blue2: string;
  Blue3: string;
  Blue4: string;
  Blue5: string;
  Blue4020: string;
  Red1: string;
  Red2: string;
  Red3: string;
  Red4: string;
  Red5: string;
  Red6: string;
  Green1: string;
  Green2: string;
  Green3: string;
  Green4: string;
  Green5: string;
  Yellow1: string;
  Yellow2: string;
  Yellow3: string;
  Yellow4: string;
  Yellow5: string;
  Yellow4040: string;
  Yellow4080: string;
  Teal2: string;
  Teal3: string;
  Teal5: string;
  Magenta2: string;
  Magenta2025: string;
  Magenta3: string;
  Magenta5: string;
  Purple2: string;
  Purple2025: string;
  Purple3: string;
  Purple5: string;
  BrandYellow: string;
  Pink2: string;
  Pink5: string;
  LightGreen2: string;
  LightGreen5: string;
  Brown2: string;
  Brown5: string;
  Transparent: string;
}

export type BaseColor = keyof IBaseColors;

export interface IArticleColors {
  Explore: BaseColor;
  Brief: BaseColor;
  Write: BaseColor;
  Monitor: BaseColor;
  Done: BaseColor;
  ToDo: BaseColor;
  OnHold: BaseColor;
  Review: BaseColor;
}

export interface IDiscoverColors {
  All: BaseColor;
  TopicGems: BaseColor;
  ExpandExpertise: BaseColor;
  BuildExpertise: BaseColor;
  BestForSeries: BaseColor;
  FastRanking: BaseColor;
  ZeroCompetition: BaseColor;
}

export const BaseColors: IBaseColors = {
  Gray1: '#161E25',
  Gray2: '#60707B',
  Gray3: '#7A8B94',
  Gray3060: '#7A8B943C',
  Gray4: '#DCE2E5',
  Gray5: '#F0F2F5',
  White1: '#FFFFFF',
  White2: '#DCE2E6',
  Blue1: '#006894',
  Blue2: '#0076A8',
  Blue3: '#0090CF',
  Blue4: '#B3E8FF',
  Blue5: '#CCF0FF',
  Blue4020: '#B3E8FF33',
  Red1: '#C60108',
  Red2: '#DF0109',
  Red3: '#FE3E45',
  Red4: '#FED2D4',
  Red5: '#FFE0E1',
  Red6: '#FF626726',
  Green1: '#037230',
  Green2: '#038136',
  Green3: '#03973E',
  Green4: '#BBEDC0',
  Green5: '#CDF4D1',
  Yellow1: '#8F5600',
  Yellow2: '#9E5F00',
  Yellow3: '#C27400',
  Yellow4: '#FFDAA3',
  Yellow5: '#FFE6C2',
  Yellow4040: 'rgb(255, 218, 163, 0.40)',
  Yellow4080: 'rgb(255, 218, 163, 0.80)',
  BrandYellow: '#F39200',
  Teal2: '#017977',
  Teal3: '#059997',
  Teal5: '#B6F6EA',
  Magenta2: '#BE3A7C',
  Magenta2025: 'rgba(190, 58, 124, 0.25)',
  Magenta3: '#E64C99',
  Magenta5: '#FFDBEF',
  Purple2: '#7C52DE',
  Purple3: '#9670F0',
  Purple5: '#EBE0FF',
  Purple2025: 'rgba(124, 82, 222, 0.25)',
  Pink2: '#D24ECD',
  Pink5: '#FFDFFE',
  LightGreen2: '#B1C005',
  LightGreen5: '#F3F6CB',
  Brown2: '#CF5700',
  Brown5: '#FCE0CB',
  Transparent: 'rgba(0,0,0,0)',
};

export const ArticleColors: IArticleColors = {
  ToDo: 'Gray1',
  Explore: 'Teal2',
  Brief: 'Magenta2',
  Write: 'Blue3',
  Monitor: 'Green3',
  Done: 'Green3',
  Review: 'Purple2',
  OnHold: 'BrandYellow',
};

export const DiscoverFrontColors: IDiscoverColors = {
  All: 'Gray1',
  TopicGems: 'BrandYellow',
  ExpandExpertise: 'Purple2',
  BuildExpertise: 'Magenta2',
  BestForSeries: 'Red2',
  FastRanking: 'Green2',
  ZeroCompetition: 'Teal2',
};

export const DiscoverBackgroundColors: IDiscoverColors = {
  All: 'Gray4',
  TopicGems: 'Yellow5',
  ExpandExpertise: 'Purple5',
  BuildExpertise: 'Magenta5',
  BestForSeries: 'Red5',
  FastRanking: 'Green5',
  ZeroCompetition: 'Teal5',
};
