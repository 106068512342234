'use client';
import React, { FC, PropsWithChildren } from 'react';
import { createGlobalStyle, ThemeProvider } from 'styled-components';
import { Animations } from './animations';
import {
  ArticleColors,
  BaseColors,
  DiscoverBackgroundColors,
  DiscoverFrontColors,
} from './colors';
import { CommonStyles } from './commonStyles';
import { Heights } from './heights';
import { Shadows } from './shadows';
import { Sizes } from './sizes';
import { ITheme } from './styled';
import { Texts } from './texts';

const theme: ITheme = {
  typography: {
    fontFamily: ['Source Sans Pro'].join(','),
  },
  colors: {
    base: BaseColors,
    article: ArticleColors,
    discover: {
      front: DiscoverFrontColors,
      background: DiscoverBackgroundColors,
    },
  },
  texts: Texts,
  sizes: Sizes,
  heights: Heights,
  commonStyles: CommonStyles,
  animations: Animations,
  shadows: Shadows,
};

/**
 * Allows definition of global styles/css variables
 */
const GlobalStyles = createGlobalStyle`
  body {
    margin: 0;
    font-family: 'Source Sans Pro', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    line-height: 1;
    overflow: hidden;
    background-color: ${(p) => p.theme.colors.base.Gray5};
  }

  fieldset {
    border: none !important;
  }

  #jsd-widget {
    display: none;
  }

  #hidden {
    position: fixed;
    bottom: 0;
    right: 0;
    display: none;
  }

  a {
    text-decoration: inherit;
    color: inherit;
    :visited {
      color: inherit;
    }
    :active {
      color: inherit;
    }
  }

  ${(p) =>
    Object.values(p.theme.animations).map((animation) => animation.keyframes)}
`;

export const Theme: FC<PropsWithChildren> = ({ children }) => (
  <ThemeProvider theme={theme}>
    <GlobalStyles />
    {children}
  </ThemeProvider>
);
