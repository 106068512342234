'use client';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import styled, { css } from 'styled-components';
import { Text } from '@/common/Text';
import { BaseColor } from '@/theme/colors';
import { StyledProps } from '@/types/helpers';

type SpinnerProps = {
  variant?: 'App' | 'Centered' | 'Inline' | 'Page' | 'Block';
  color?: BaseColor;
  size?: 'M' | 'S';
  className?: string;
};

type LoadingSpinnerProps = StyledProps<
  Pick<SpinnerProps, 'color'> & {
    size: number;
  }
>;

const LoadingSpinner = styled.div<LoadingSpinnerProps>`
  width: ${(p) => p.$size}px;
  height: ${(p) => p.$size}px;
  border: ${(p) => Math.round(p.$size * 0.091)}px solid
    ${(p) => p.theme.colors.base[p.$color ?? 'Blue3']};
  border-bottom-color: transparent;
  border-radius: 50%;
  box-sizing: border-box;

  @keyframes rotate-loading-spinner {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }

  animation: rotate-loading-spinner 0.8s linear infinite;
`;

const Wrapper = styled.div<StyledProps<SpinnerProps>>`
  ${(p) => {
    switch (p.$variant) {
      case 'Centered':
        return css`
          position: absolute;
          left: 50%;
          top: 50%;
          transform: translate(-50%, -50%);
        `;

      case 'Inline':
        return css`
          display: flex;
          width: 100%;
          align-items: center;
          flex-direction: column;
        `;
      case 'Block':
        return css``;

      case 'Page':
        return css`
          position: fixed;
          left: 50%;
          top: 50%;
          transform: translate(-50%, -50%);
          z-index: 999999999998;
        `;

      default:
        return css`
          display: flex;
          justify-content: center;
          align-items: center;
          margin-top: 200px;
        `;
    }
  }}
`;

export const Spinner: FC<SpinnerProps> = ({
  color,
  variant,
  size = 'M',
  className,
}) => (
  <Wrapper $color={color} $variant={variant} className={className}>
    <LoadingSpinner $size={size === 'M' ? 44 : 20} $color={color} />
  </Wrapper>
);

const SpinnerInlineWithTextWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  flex-direction: column;
`;

const SpinnerWrapper = styled.div`
  display: flex;
  column-gap: 4px;
`;

export const SpinnerInlineWithText: FC<{
  className?: string;
  withkeepUsingRellifyText?: boolean;
  color?: BaseColor;
}> = ({ withkeepUsingRellifyText = true, className, color }) => {
  const { t } = useTranslation();

  return (
    <SpinnerInlineWithTextWrapper className={className}>
      <SpinnerWrapper>
        <LoadingSpinner $size={20} />
        <Text variant="Paragraph2" color={color}>
          {t('global:loading')}
        </Text>
      </SpinnerWrapper>
      {withkeepUsingRellifyText && (
        <>
          <Text variant="Caption2" color="Gray2">
            {t('loadingAi:topRankings:severalMinutes')}
          </Text>
          <Text variant="Caption2" color="Gray2">
            {t('loadingAi:keepUsingRellify')}
          </Text>
        </>
      )}
    </SpinnerInlineWithTextWrapper>
  );
};
